import imageUrlBuilder from '@sanity/image-url';
import { sanityConfig } from '../config';
import { SanityBasicImage } from '../../graphql-types';
import { FixedObject } from 'gatsby-image';

const builder = (imageUrlBuilder as any).default
  ? (imageUrlBuilder as any).default(sanityConfig.api)
  : imageUrlBuilder(sanityConfig.api);

export function buildImageObj(source: SanityBasicImage) {
  return {
    asset: { _ref: source.asset._id },
    crop: source.crop || undefined,
    hotspot: source.hotspot || undefined
  };
}

export const imageUrlFor = builder.image.bind(builder);

export function getAbsoluteUrl(baseUrl: string, resourcePath: string) {
  baseUrl = baseUrl.trim();
  resourcePath = resourcePath.trim();

  if (baseUrl.endsWith('/')) {
    baseUrl = baseUrl.slice(0, -1);
  }

  if (resourcePath.startsWith('/')) {
    resourcePath = resourcePath.substring(1);
  }

  return `${baseUrl}/${resourcePath}`;
}

export function flattenQueryCollection<T>(items: {
  edges: { node: any }[];
}): T[] {
  return items.edges
    .map(i => i.node)
    .filter(i => i.logo.asset && i.description);
}

export const getImageWithFit = (
  image: SanityBasicImage,
  height: number,
  width: number
): FixedObject => {
  const src = imageUrlFor(image)
    .height(height)
    .width(width)
    .fit('crop')
    .quality(100)
    .url();

  return {
    srcSet: `${src} ${width}px`,
    src,
    height,
    width
  };
};

export const sluggify = (title: string) => {
  const regexValue = /[^A-Za-z0-9-]/g;
  const regexSpace = /\s/g;
  return title
    .trim()
    .replace(regexSpace, '-')
    .replace(regexValue, '')
    .toLowerCase();
};

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);
