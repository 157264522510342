import React from 'react';
import Helmet from 'react-helmet';

const NoIndexMetaTag: React.FC = () => {
  return (
    <Helmet>
      <meta name="robots" content="noindex"></meta>
      <meta name="googlebot" content="noindex"></meta>
    </Helmet>
  );
};

export default NoIndexMetaTag;
