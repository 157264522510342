import React from 'react';
import { IllustrativeIcon } from '../../atoms/icons/illustrative-icon/illustrative-icon';
import { StructuredProps } from '../../types';

const OurDoctors: StructuredProps = {
  headline: 'A team designed to deliver results.',
  items: [
    {
      id: 1,
      heading: 'Doctors',
      description:
        'Your clinician gets to know you from the inside-out—including health history and current symptoms—to help you address the underlying causes of your health concerns.',
      illustration: (
        <IllustrativeIcon color="sage" iconWidth={128} name="doctor" />
      )
    },
    {
      id: 2,
      heading: 'Health Coaches',
      description:
        'Your health coach is dedicated to keeping you on track and easing the addition of lifestyle changes that help optimize healthy movement, nutrition, sleep, and stress management.',
      illustration: (
        <IllustrativeIcon color="sage" iconWidth={128} name="personal" />
      )
    },
    {
      id: 3,
      heading: 'Care Managers',
      description:
        'Your care manager makes sure things run smoothly with scheduling, coordinating labs, and insurance or FSA/HSA questions.',
      illustration: (
        <IllustrativeIcon color="sage" iconWidth={128} name="teamCollab" />
      )
    }
  ],
  cta: {
    label: 'Meet Our Clinicians',
    destination: '/providers',
    ctaTrackingLabel:
      'Meet Our Clinicians button on Why it Works page - A team designed to deliver results module'
  }
};

export default OurDoctors;
