export interface SanityConfig {
  readonly api: {
    readonly dataset: string;
    readonly projectId: string;
  };
}

export const sanityConfig: SanityConfig = {
  api: {
    dataset: process.env.GATSBY_SANITY_DATASET || '',
    projectId: process.env.GATSBY_SANITY_PROJECT_ID || ''
  }
};
