import React, { useCallback } from 'react';
import {
  Hero,
  Module,
  SingleQuote,
  HalfAndHalf,
  TextLockup,
  Text,
  Button,
  StandardCarousel,
  Structured,
  NewsletterSignUpForm
} from '../redesign';
import { Layout } from '../redesign';
import { default as SEO } from '../components/SEO';
import heroContent from '../redesign/content/state-change/hero.json';
import quoteCarouselContent from '../redesign/content/state-change/quote-carousel.json';
import kickstartYourJourneyContent from '../redesign/content/state-change/kickstart-your-journey.json';
import whatWeTreatContent from '../redesign/content/what-we-treat/what-we-treat.json';
import ourDoctorsContent from '../redesign/content/our-doctors/our-doctors';
import halfAndHalfWithTextLockupContent from '../redesign/content/state-change/half-and-half-with-text-lockup.json';
import { useIsMobileBreakpoint } from '../hooks/use-is-mobile-breakpoint';
import { useGclid } from '../hooks/use-gclid';
import { track } from '@parsleyhealth/cilantro-track';

export default function StateChange() {
  const gclid = useGclid();
  const isMobile = useIsMobileBreakpoint();
  const handleSubmitCallback = useCallback(
    (email: string, setMessaage: (message: string) => void): void => {
      window.heap.identify(email);
      window.heap.addUserProperties({
        gclid,
        state_change_newsletter_form: 'true'
      });
      track('State Change Sign Up', {
        email: email
      });
      setMessaage('Success!');
    },
    [gclid]
  );
  const memoizedCTA = useCallback(
    () => (
      <NewsletterSignUpForm
        handleSubmit={handleSubmitCallback}
        label="Sign up for your exclusive offer"
        labelColor="dark-green"
        ctaTrackingLabel="Newsletter sign up button on State Change page"
        formTrackingLabel="Newsletter sign up form in State Change hero"
      />
    ),
    [handleSubmitCallback]
  );
  return (
    <Layout footerContentKey="stateChange">
      <SEO pageTitle="State Change" pageDescription="State Change" />
      <Module>
        <Hero asCTA={memoizedCTA} {...heroContent} />
      </Module>
      <Module>
        <SingleQuote {...quoteCarouselContent} />
      </Module>
      <Module>
        <HalfAndHalf
          image={kickstartYourJourneyContent.image}
          bgColor="gray-nurse"
          imagePosition="right"
          paddingY={false}
          inset={!isMobile}
          fullImage
        >
          <TextLockup alignment="center">
            {kickstartYourJourneyContent.eyebrow && (
              <Text variant="p3b" className="hero-eyebrow mb-xs">
                {kickstartYourJourneyContent.eyebrow}
              </Text>
            )}
            <Text variant="h1a" className="hero-headline mb-xs px-xs">
              {kickstartYourJourneyContent.headline}
            </Text>
            {kickstartYourJourneyContent.description && (
              <Text variant="p2a" className="hero-description mb-xs px-xs">
                {kickstartYourJourneyContent.description}
              </Text>
            )}
            <div className="inline-flex-center hero-buttons-container">
              <div className="text-lockup-buttons-container">
                {kickstartYourJourneyContent.primaryCta && (
                  <Button
                    color="sage"
                    variant="filled"
                    className="hero-primary-button"
                    destination={
                      kickstartYourJourneyContent.primaryCta.destination
                    }
                    trackingLabel={
                      kickstartYourJourneyContent.primaryCta.ctaTrackingLabel
                    }
                  >
                    {kickstartYourJourneyContent.primaryCta.label}
                  </Button>
                )}
                {kickstartYourJourneyContent.secondaryCta && (
                  <Button
                    variant="outlined"
                    color="darkGreen"
                    destination={
                      kickstartYourJourneyContent.secondaryCta.destination
                    }
                    trackingLabel={
                      kickstartYourJourneyContent.secondaryCta.ctaTrackingLabel
                    }
                  >
                    {kickstartYourJourneyContent.secondaryCta.label}
                  </Button>
                )}
              </div>
            </div>
          </TextLockup>
        </HalfAndHalf>
      </Module>
      <Module>
        <StandardCarousel
          eyebrow={whatWeTreatContent.eyebrow}
          heading={whatWeTreatContent.heading}
          slides={whatWeTreatContent.items}
        />
      </Module>
      <Module>
        <Structured
          heading={ourDoctorsContent.headline}
          items={ourDoctorsContent.items}
          alignment="center"
          cta={ourDoctorsContent.cta}
        />
      </Module>
      <Module>
        <HalfAndHalf image={halfAndHalfWithTextLockupContent.image}>
          <TextLockup alignment={isMobile ? 'center' : 'left'}>
            <Text variant="p3b" className="hero-eyebrow mb-xs">
              {halfAndHalfWithTextLockupContent.eyebrow}
            </Text>
            <Text variant="h2a" className="text-lockup-headline">
              {halfAndHalfWithTextLockupContent.headline}
            </Text>
            <div className="inline-flex-center text-lockup-buttons-container">
              <Button
                color="sage"
                variant="filled"
                className="text-lockup-button"
                destination={halfAndHalfWithTextLockupContent.cta.destination}
                trackingLabel={
                  halfAndHalfWithTextLockupContent.cta.ctaTrackingLabel
                }
              >
                {halfAndHalfWithTextLockupContent.cta.label}
              </Button>
            </div>
          </TextLockup>
        </HalfAndHalf>
      </Module>
      <Module marginTop="lg" />
    </Layout>
  );
}
